.container-timer {
  width: 200px;
  text-align: center;
}

.timer {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  float: none;
  margin: 0 auto;
}

.timer div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #cfcfd0;
  border-radius: 50%;
  animation: timer 1.2s linear infinite;
}

.timer div:nth-child(2) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}

.timer div:nth-child(3) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}

.timer div:nth-child(4) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}

.timer div:nth-child(5) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}

.timer div:nth-child(6) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}

.timer div:nth-child(7) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}

.timer div:nth-child(8) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}

.timer div:nth-child(9) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}

.timer div:nth-child(10) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}

.timer div:nth-child(11) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}

.timer div:nth-child(12) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}

.timer div:nth-child(13) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}

.timer span {
  position: absolute;
  font-family: 'Teko', sans-serif;
  top: 25px;
  left: 28px;
  font-size: 25px;
  color: white;
  width: 25px;
  text-align: center;
}

@keyframes timer {
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}

.container-timer .text-container-timer {
  font-size: 14px;
  line-height: 18px;
  font-family: 'CenturyGothicBold';
  color: white;
}
